import { Injectable, signal } from '@angular/core';
import { Modal } from './modal.enum';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    public openedModal = signal<Modal | undefined>(undefined);

    open(component: Modal): void {
        this.openedModal.set(component);
    }

    close() {
        this.openedModal.set(undefined);
    }

    checkModalWindow(e: Event): void {
        const div: HTMLDivElement | null = document.querySelector('.modal-parent');

        if (!div) return;

        if (!this.openedModal) return;

        if (e.target !== div && e.target !== div.children[0]) return;

        this.close();
    }
}
