import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: [ './error.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ErrorComponent {
  @Input() error: string = '';
}
